import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import type { ToastType } from '@/utils/constants/enums'

export type ToastOptions = {
  description?: string
  duration?: number
  onClose?: () => void
  title: string
  type?: ToastType
}

/**
 * Store
 */

export const useToastStore = create(
  combine(
    {
      current: null as null | ToastOptions,
    },
    (set) => ({
      dismiss() {
        set({ current: null })
      },
      show(current: ToastOptions) {
        set({ current })
      },
    }),
  ),
)

/**
 * Helpers
 */

export function useShowToast() {
  return useToastStore((state) => state.show)
}
