import { createContext, useContext, useEffect, useState } from 'react'

import { StorageKey } from '@/utils/constants/enums'
import * as storage from '@/utils/storage'

export type ColorScheme = 'dark' | 'default'

export const ColorSchemeVariables = {
  dark: 'dark' as ColorScheme,
  default: 'default' as ColorScheme,
}

export type ColorSchemeState = {
  colorScheme: ColorScheme
  toggleColorScheme: () => void
}

// CONTEXT
export const ColorSchemeContext = createContext<ColorSchemeState>({
  colorScheme: ColorSchemeVariables.default,
  toggleColorScheme: () => null,
})
export const useColorSchemeContext = () => useContext(ColorSchemeContext)

// HOOK

export function useColorScheme(): ColorSchemeState {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    ColorSchemeVariables.default,
  )

  useEffect(() => {
    // On initial mount, check if a user preference is stored and use that
    const systemPreference = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? ColorSchemeVariables.dark
      : ColorSchemeVariables.default

    const localColorScheme = storage.get(StorageKey.ColorScheme)

    setColorScheme(
      localColorScheme ? (localColorScheme as ColorScheme) : systemPreference,
    )
  }, [])

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => {
      const newColorScheme =
        prevScheme === ColorSchemeVariables.default
          ? ColorSchemeVariables.dark
          : ColorSchemeVariables.default
      storage.set(StorageKey.ColorScheme, newColorScheme) // Store the user theme preference
      return newColorScheme
    })
  }

  return { colorScheme, toggleColorScheme }
}
