import type { ComponentProps, ReactNode } from 'react'

import type { AsyncButton } from '@ocho/aurora'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'

export type AlertAction = {
  label: string
  onClick?: (noteRef: any) => Promise<void> | void
} & ComponentProps<typeof AsyncButton>

export type AlertOptions = {
  actions?: AlertAction[]
  children?: ReactNode
  /**
   * @deprecated use 'message' instead
   */
  description?: string
  isCancelable?: boolean
  message?: string
  onClose?: () => void
  // FIXME: this props can be changed for a type
  showComment?: boolean
  title?: string
  titleHelper?: string
}

/**
 * Store
 */

export const useAlertStore = create(
  combine({ current: null as AlertOptions | null }, (set) => ({
    dismiss() {
      set({ current: null })
    },
    show(alertOptions: AlertOptions) {
      set({ current: alertOptions })
    },
  })),
)

/**
 * Helpers
 */

export function useShowAlert() {
  return useAlertStore((state) => state.show)
}
