import { StorageKey } from './constants/enums'
import queryClient from './queryClient'

export function isValid() {
  return (
    window.localStorage !== undefined &&
    window.localStorage.setItem !== undefined &&
    typeof window.localStorage.setItem === 'function'
  )
}

export function get(key: StorageKey) {
  try {
    return localStorage.getItem(key)
  } catch {
    void 0
  }
}

export function set(key: StorageKey, value: string) {
  try {
    localStorage.setItem(key, value)
  } catch {
    void 0
  }
}

export function remove(key: StorageKey) {
  try {
    localStorage.removeItem(key)
  } catch {
    void 0
  }
}

/**
 * Clears local storage and tanstack query cache, but keeps the selected language
 */
export function clear() {
  try {
    const selectedLanguage = localStorage.getItem(StorageKey.Language)

    queryClient.clear()
    localStorage.clear()

    if (selectedLanguage) {
      localStorage.setItem(StorageKey.Language, selectedLanguage)
    }
  } catch {
    void 0
  }
}
