import { Amplify } from 'aws-amplify'
import type { AuthSession, AuthUser } from 'aws-amplify/auth'
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'

import { getPartnerDataFromAuthSession } from '@/containers/SelectLocation'

import awsConfig from '@/utils/aws'
import { useAuthStore } from '@/utils/store/auth'
/**
 * NOTE: Hub must come before Amplify.configure
 */
Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signedIn':
    case 'signInWithRedirect':
    case 'tokenRefresh': {
      Promise.all([getCurrentUser(), fetchAuthSession()])
        .catch(() => null)
        .then(([authUser, authSession]: [AuthUser, AuthSession]) => {
          useAuthStore.setState((state) => ({
            ...state,
            currentUser: authUser,
            isAuthenticated: Boolean(authUser),
            isLoading: false,
            partnerData: getPartnerDataFromAuthSession(authSession),
          }))
        })
      break
    }
    case 'signedOut': {
      useAuthStore.setState((state) => ({
        ...state,
        currentUser: null,
        isAuthenticated: false,
        isLoading: false,
        partnerData: { name: undefined },
      }))
      break
    }
    case 'signInWithRedirect_failure':
    case 'tokenRefresh_failure':
      // eslint-disable-next-line no-console
      console.log('user sign in failed')
      break
  }
})

/**
 * NOTE: Amplify.configure must come after Hub.listen
 */
Amplify.configure(awsConfig)
